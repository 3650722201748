import {
  AnimatedAlert,
  Chip,
  GlobalIcon,
  SecureEncryptor,
  ShowPrice,
  TextWithInfoIcon,
  Toggle,
  Typography,
  useDevice,
} from "get-life-storybook-ts";
import numberFormat, {
  numberFormatPayment,
} from "../../../../../../utils/Utils";
import Coupon, { ConditionsPromoLink } from "../../../Coupon/Coupon.component";
import {
  CouponInfoI,
  ValidateCouponT,
} from "../../../PaymentPage/PaymentPage.controller";
import { getMessage } from "../PaymentCardContent/PaymentCardContent.component";
import { NewCheckoutI } from "../../../../../../api/request/Lead/Interfaces/DataInterface.response";
import {
  formatGetlifeDate,
  validDateYear,
} from "../../../../../../utils/PrepareDataCalendar";
import { useContext, useRef } from "react";
import { GetlifeContext } from "../../../../../../context/GetLifeContext.context";
import { DateTime } from "luxon";
import BenefitsModal from "../../../../../../components/BenefitsModal/BenefitsModal";
import { DOMAIN } from "../../../../../../constants/Global";
import { GlobalDomainQuestions } from "../../../../../../utils/InternationlChanges";

interface ResumePriceSQLI {
  couponInfo?: CouponInfoI;
  checkout: NewCheckoutI;
  handleAddCoupon: (code: string, yearlyPrice: number) => void;
  handlePurchasePeriod: (value: string) => void;
  handleRemoveCoupon: (code: string) => void;
  isAvailableToggle: boolean;
  isButtonDisabled: boolean;
  translate: (key: string) => string;
  validateCoupon?: ValidateCouponT;
  onConditionsClick: () => void;
  capitals: any;
  prices: any;
  promotion: any;
}

export const promotionPrice = ({ checkout }: { checkout: NewCheckoutI }) => {
  if (checkout.period?.toLowerCase() === "yearly") {
    const resultYearly =
      (checkout.feeCollection?.length > 0 && checkout.feeCollection[0].total) ||
      checkout.priceToPay;
    return numberFormatPayment(resultYearly!);
  }
  return checkout.priceToPay ? numberFormatPayment(checkout.priceToPay!) : "0";
};

const ResumePriceSQL: React.FC<ResumePriceSQLI> = ({
  couponInfo,
  checkout,
  handleAddCoupon,
  handlePurchasePeriod,
  handleRemoveCoupon,
  isAvailableToggle,
  translate,
  validateCoupon,
  onConditionsClick,
  capitals,
  prices,
  promotion,
}) => {
  const { isMobile } = useDevice();

  const { domain } = useContext(GetlifeContext);

  const alertRef = useRef<any>(null);
  const benefitsModalRef = useRef<any>(null);

  const couponCondition = !!checkout.firstPayment;

  let total = 0;

  const capital =
    capitals &&
    Object.keys(capitals).map(
      (key) => capitals[key]?.active && capitals[key]?.capital
    );
  capital &&
    capital.forEach(function (uniqueCapital: number) {
      total += uniqueCapital;
    });

  return (
    <>
      {isMobile && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            alertRef?.current?.toggleAlert();
          }}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              e.preventDefault();
              alertRef?.current?.toggleAlert();
            }
          }}
        >
          <Chip
            type="success"
            text={translate("paymentChallenger.priceDetails.capitalOf").replace(
              "{capital}",
              `${numberFormatPayment(total, "capital")} €`
            )}
            icon="LockIcon"
          />
        </div>
      )}
      <Typography
        variant="H3"
        weight="700"
        text={translate("paymentChallenger.resumePrice.title")}
      />
      {!isMobile && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            alertRef?.current?.toggleAlert();
          }}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              e.preventDefault();
              alertRef?.current?.toggleAlert();
            }
          }}
        >
          <Chip
            type="success"
            text={translate("paymentChallenger.priceDetails.capitalOf").replace(
              "{capital}",
              `${numberFormatPayment(total, "capital")} €`
            )}
            icon="LockIcon"
          />
        </div>
      )}

      {isAvailableToggle && (
        <>
          <TextWithInfoIcon
            background="white"
            content={translate("paymentChallenger.resumePrice.infoText")}
            infoIcon
            weight="400"
            overlayButtonLabel="Aceptar"
            overlayTitle={
              <>
                <Typography
                  variant="H3"
                  weight="700"
                  text={translate(
                    "paymentChallenger.resumePrice.overlay.title"
                  )}
                />
              </>
            }
            overlayPrice={
              <>
                <Typography variant="Body" text={""}>
                  {getMessage({
                    message: translate(
                      "paymentChallenger.resumePrice.overlay.text"
                    ),
                    replace: (
                      <b>
                        {translate(
                          "paymentChallenger.resumePrice.overlay.text.bold"
                        )}
                      </b>
                    ),
                  })}
                </Typography>
                <br></br>
                <Typography
                  variant="Body"
                  weight="700"
                  text={translate(
                    "paymentChallenger.resumePrice.overlay.textSecond"
                  )}
                />
              </>
            }
          />
          <Toggle
            initialValue={
              checkout.period?.toLowerCase() !== "yearly" ? false : true
            }
            labels={[
              translate("paymentChallenger.resumePrice.toggle.first"),
              translate("paymentChallenger.resumePrice.toggle.second"),
            ]}
            onValueChange={(e) =>
              handlePurchasePeriod(!e ? "monthly" : "yearly")
            }
          />
        </>
      )}
      <hr></hr>
      <div className="paymentChallenger__body__sections--resumePrice--price">
        <div className="paymentChallenger__body__sections--resumePrice--price--details">
          <div className="flex flex-col gap-1">
            <span className="BodyL font-medium text-[#555555]">
              {translate("paymentChallenger.priceDetails.insurance")}
            </span>
            <div>
              {Object.keys(capitals).map(
                (key) =>
                  capitals[key]?.active && (
                    <>
                      <div className="flex flex-row">
                        <span className="BodyM font-light text-[#7B7B7B]">
                          {translate(
                            `payment.multiproduct.${capitals[key]?.trait
                              .toLowerCase()
                              .trim()
                              .replace(/[-_\s]+(.)?/g, (_: string, c: string) =>
                                c ? c.toUpperCase() : ""
                              )}`
                          )}
                          {`: ${numberFormat(
                            capitals[key]?.capital,
                            "capital"
                          )}`}
                        </span>
                      </div>
                    </>
                  )
              )}

              <span className="BodyM font-medium text-[#7B7B7B]">
                {translate(`payment.total.capital`)}
                {`: ${numberFormat(total, "capital")}`}
              </span>
            </div>
          </div>
          <Typography
            variant="Span"
            weight="700"
            text=""
            className="monthlyPrice"
          >
            {`${numberFormatPayment(
              checkout.period?.toLowerCase() === "monthly"
                ? prices.monthly.real
                : prices.yearly.real
            )} € ${translate(
              `paymentChallenger.resumePrice.${checkout.period?.toLowerCase()}`
            )}`}
          </Typography>
        </div>
        <ShowPrice
          finalPrice={numberFormatPayment(
            checkout.period?.toLowerCase() === "monthly"
              ? prices.monthly.real
              : prices.yearly.real
          )}
          frequencyText={translate(
            `paymentChallenger.resumePrice.${checkout.period?.toLowerCase()}`
          )}
          totalText={translate("paymentChallenger.resumePrice.total")}
          showRegularPrice={promotion.promotionalCode}
          promotionPrice={
            promotion.promotionalCode
              ? numberFormatPayment(
                  checkout.period?.toLowerCase() === "monthly"
                    ? prices.monthly.promoted
                    : prices.yearly.promoted
                )
              : undefined
          }
        />
        {couponInfo && (
          <div className="paymentChallenger__body__sections--resumePrice--price--conditions">
            <ConditionsPromoLink
              translate={translate}
              onConditionsClick={onConditionsClick}
            />
          </div>
        )}
      </div>
      {(domain === "es" ||
        domain === "pt" ||
        domain === "it" ||
        domain === "sql_es" ||
        domain === "wl_es") &&
        couponCondition && (
          <div className="paymentChallenger__body__sections--promoted">
            <div>
              <GlobalIcon iconName="CalendarTimeIcon" size="XS" />
            </div>
            <Typography variant="BodySmall" text="" color="dark-gray-100">
              {getMessage({
                message: translate(
                  `payment.paymentCard.checkBox.promoted.${checkout.purchasePeriod?.toLowerCase()}`
                )
                  .replace(
                    "%",
                    formatGetlifeDate(
                      DateTime.now(),
                      GlobalDomainQuestions[domain]
                    )
                  )
                  .replace(
                    "&",
                    validDateYear(GlobalDomainQuestions[domain], "dd-MM-yyyy")
                  )
                  .replace(
                    "#",
                    validDateYear(GlobalDomainQuestions[domain]).split(",")[0]
                  ),
                replace: (
                  <Typography
                    variant="SpanSmall"
                    weight="700"
                    color="life-blue-100"
                    text={numberFormat(
                      promotion.promotionalCode
                        ? checkout.period?.toLowerCase() === "monthly"
                          ? prices.monthly.promoted
                          : prices.yearly.promoted
                        : checkout.period?.toLowerCase() === "monthly"
                        ? prices.monthly.real
                        : prices.yearly.real,
                      "price"
                    )}
                  />
                ),
              })}
            </Typography>
          </div>
        )}
      <Coupon
        couponCode={checkout.promotionalCode}
        headerLabel={translate("coupons.accordion.headerContentAB")}
        translate={translate}
        validateCoupon={validateCoupon}
        handleClick={handleAddCoupon}
        handleRemove={handleRemoveCoupon}
        yearlyPrice={checkout.finalYearlyPrice}
        isRemovable={checkout.promoRemovable}
        onConditionsClick={onConditionsClick}
        couponInfo={
          couponInfo ? (
            <ConditionsPromoLink
              translate={translate}
              onConditionsClick={onConditionsClick}
            />
          ) : undefined
        }
      />
      {!isMobile && (
        <div className="paymentChallenger__body__sections--resumePrice--final">
          <SecureEncryptor />
        </div>
      )}
      <BenefitsModal
        ref={benefitsModalRef}
        title={translate("calculatorPage.benefitsModal.title")}
        benefits={[
          {
            icon: (
              <GlobalIcon
                iconName="CalendarBrandIcon"
                size="M"
                color="var(--theme-primary)"
                filled="var(--theme-fill)"
              />
            ),
            title: translate("calculatorPage.benefitsModal.benefit1.title"),
            description: translate(
              "calculatorPage.benefitsModal.benefit1.description"
            ),
          },
          {
            icon: (
              <GlobalIcon
                iconName="TransparencyIcon"
                size="M"
                color="var(--theme-primary)"
                filled="var(--theme-fill)"
              />
            ),
            title: translate("calculatorPage.benefitsModal.benefit2.title"),
            description: translate(
              "calculatorPage.benefitsModal.benefit2.description"
            ),
          },
          {
            icon: (
              <GlobalIcon
                iconName="SaveMoneyIcon"
                size="M"
                color="var(--theme-primary)"
                filled="var(--theme-fill)"
              />
            ),
            title: translate("calculatorPage.benefitsModal.benefit3.title"),
            description: translate(
              "calculatorPage.benefitsModal.benefit3.description"
            ),
          },
        ]}
      />
    </>
  );
};

export default ResumePriceSQL;
